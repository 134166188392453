<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        id="addNewContact"
        ref="addNewContact"
        class="custom-form pt-6"
        @submit.prevent="submitContactForm"
      >
        <v-row>
          <v-col cols="6">
            <v-text-field
              outlined
              label="Imię"
              v-model="contact.firstName"
              placeholder="Wpisz imię"
            />
          </v-col>

          <v-col cols="6">
            <v-text-field
              outlined
              label="Nazwisko"
              v-model="contact.lastName"
              placeholder="Wpisz nazwisko"
            />
          </v-col>

          <v-col cols="12">
            <v-combobox
              outlined
              label="Rola"
              v-model="contact.role"
              :menu-props="{offsetY: true}"
              :items="contactRoles"
              :rules="[rules.required]"
              placeholder="Wpisz lub wybierz rolę"
            />
          </v-col>

          <v-col cols="6">
            <v-text-field
              outlined
              label="Numer Telefonu"
              v-model="contact.phoneNumber"
              :rules="[rules.phoneNumber]"
              v-mask="getFieldMask('phoneNumber')"
              placeholder="Wpisz numer telefonu"
            />
          </v-col>

          <v-col cols="6">
            <v-text-field
              outlined
              label="Email"
              v-model="contact.email"
              :rules="emailRules"
              placeholder="Wpisz email"
            />
          </v-col>

          <v-col
            cols="12"
            class="pb-0"
          >
            <v-autocomplete
              :items="client.addresses"
              label="Obsługiwane adresy"
              v-model="contact.addressIds"
              placeholder="Wybierz adresy"
              :item-text="(address) => address.name || address.formattedAddress"
              item-value="id"
              outlined
              dense
              multiple
            >
              <template #selection="{ item, index }">
                <MultiselectChoices
                  :index="index"
                  :label="shortenName(item.name || item.formattedAddress)"
                  :array-length="contact.addressIds.length"
                />
              </template>
            </v-autocomplete>
          </v-col>

          <v-col
            cols="12"
            class="pt-0"
          >
            <v-checkbox
              v-model="contact.pricesVisible"
              label="Widoczność cen"
              hide-details
              class="mt-0"
            />
          </v-col>

          <v-col
            cols="12"
            class="pt-0"
          >
            <v-checkbox
              v-model="contact.invoiceReceivable"
              label="Otrzymywanie dokumentów"
              hide-details
              class="mt-0"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        type="submit"
        class="form-action"
        :loading="isProcessing"
        form="addNewContact"
      >
        Zapisz
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import MultiselectChoices from '../Elements/MultiselectChoices.vue'
import rules from '../../utils/validators'
import { mapState, mapActions } from 'vuex'
import afterFormSubmitted from '../../mixins/afterFormSubmitted'
import { getFieldMask } from '../../utils'

export default {
  components: {
    DialogFormWrapper,
    MultiselectChoices,
  },
  mixins: [afterFormSubmitted],
  props: {
    edit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      rules,
      contact: {
        firstName: null,
        lastName: null,
        role: null,
        phoneNumber: null,
        email: null,
        addressIds: [],
        pricesVisible: false,
        invoiceReceivable: false,
      }
    }
  },
  computed: {
    ...mapState({
      client: state => state.client.entity,
      editingContact: state => state.layout.dialog.item,
      contactRoles: state => state.core.filters.contactRoles,
      isProcessing: state => state.client.isProcessing
    }),
    emailRules () {
      return this.editingContact?.passwordExists
        ? [rules.email, rules.required] : [rules.email]
    }
  },
  mounted () {
    if (this.edit) {
      this.contact = {
        ...this.editingContact,
        addressIds: this.editingContact.addresses.map(address => address.id)
      }
    }
  },
  methods: {
    ...mapActions({
      addNewContact: 'clients/addNewItem',
      editContact: 'clients/editItem',
      closeDialog: 'layout/closeDialog',
    }),
    getFieldMask,
    shortenName (name) {
      return name.length > 50 ? name.substring(0, 50).trim() + '...' : name
    },
    submitContactForm () {
      if (this.$refs.addNewContact.validate()) {
        if (this.edit) {
          this.editContact({ params: this.contact, id: this.contact.id, tableName: 'contacts' })
            .then(() => { this.afterFormSubmitted('Zaktualizowano osobę kontaktową') })
        } else {
          const params = { ...this.contact, clientId: this.client.id }
          this.addNewContact({ params, endpoint: 'contacts' })
            .then(() => { this.afterFormSubmitted('Dodano nową osobę kontaktową') })
        }
      }
    }
  }
}
</script>
